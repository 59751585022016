
import Home from "../views/home/index.vue";

export const routes = [
    {
        path: "*",
        component: () => import('../views/error/err404.vue'),
        hidden: true
    },
    {
        path: "/401",
        component: () => import('../views/error/err401.vue'),
        name: "401",
        hidden: true
    },
    {
        path: "/500",
        component: () => import('../views/error/err500.vue'),
        name: "500",
        hidden: true
    },
    {
        path: "/",
        component: Home,
        redirect: "/readme/main",
        name: "首页",
        hidden: true
    },
    {
        path: "/readme",
        component: Home,
        icon: "icon-kongzhitai",
        name: "控制台",
        noDropdown: true,
        children: [
            {
                path: "main",
                component: () => import('../views/home/main')
            }
        ]
    },
    {
        path: "/login",
        component: () => import('../views/login.vue'),
        meta: {
            title: ''
        }
    },
]

export const authRoutes = [
    {
        path: "/admin",
        component: Home,
        icon: "icon-gonggongquanxian",
        name: "权限管理",
        meta: {
            authRule: ["/auth"]
        },
        children: [
            {
                path: "index",
                component: () => import('../views/admin/authAdmin.vue'),
                name: "管理员管理",
                icon: "",
                meta: {
                    authRule: ["/admin/list"]
                },
            },
            {
                path: "rule",
                component: () => import('../views/admin/adminPermissionRule.vue'),
                name: "权限规则",
                icon: "",
                meta: {
                    authRule: ["/admin/permissionRule/list"]
                },
            },
            // {
            //     path: "log",
            //     component: () => import('../views/admin/log.vue'),
            //     name: "操作日志",
            //     icon: "",
            //     meta: {
            //         authRule: ["/admin/adminLog/list"]
            //     },
            // }
        ]
    },
    {
        path: "/system",
        component: Home,
        icon: "icon-xitongguanli",
        name: "系统管理",
        meta: {
            authRule: ["/system"]
        },
        children: [
            {
                path: "setting",
                component: () => import('../views/system/setting.vue'),
                name: "系统配置",
                icon: "",
                meta: {
                    authRule: ["/admin/setting/list"]
                },
            },
            // {
            //     path: "notice",
            //     component: () => import('../views/system/notice.vue'),
            //     name: "定时任务",
            //     icon: "",
            //     meta: {
            //         authRule: ["/admin/notice/list"]
            //     },
            // },
        ]
    },
    {
        path: "/group",
        component: Home,
        icon: "icon-chanpin",
        name: "群组管理",
        meta: {
            authRule: ["/group"]
        },
        children: [
            {
                path: "classifyList",
                component: () => import('../views/group/groupClassify.vue'),
                name: "群组分类",
                icon: "",
                meta: {
                    authRule: ["/admin/groupClassify/list"]
                },
            },
            {
                path: "list",
                component: () => import('../views/group/group.vue'),
                name: "群组列表",
                icon: "",
                meta: {
                    authRule: ["/admin/group/list"]
                },
            },
            {
                path: "info",
                component: () => import('../views/group/info.vue'),
                name: "群组信息",
                icon: "",
                meta: {
                    authRule: ["/admin/groupInfo/list"]
                },
            },
            {
                path: "bot",
                component: () => import('../views/bot/bot.vue'),
                name: "机器人列表",
                icon: "",
                meta: {
                    authRule: ["/admin/bot/list"]
                },
            },
            {
                path: "message",
                component: () => import('../views/bot/message.vue'),
                name: "消息列表",
                icon: "",
                meta: {
                    authRule: ["/admin/message/list"]
                },
            },
            {
                path: "job",
                component: () => import('../views/group/groupJob.vue'),
                name: "定时任务",
                icon: "",
                meta: {
                    authRule: ["/admin/groupJob/list"]
                },
            },
        ]
    },
]
